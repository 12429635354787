import * as types from "../constants";

const initialState = {
  userType: 'admin'
};

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
      case types.LOGIN_USERTYPE:
        return {
          ...state,
          userType: actions.payload
        };
  
      default:
        return state;
    }
  }