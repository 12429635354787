import React from "react";
import { toastr } from "react-redux-toastr";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      message: "",
      type: "success",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    };
  }

  showToastr() {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };

    const toastrInstance =
      this.state.type === "info"
        ? toastr.info
        : this.state.type === "warning"
        ? toastr.warning
        : this.state.type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance(
      this.state.title,
      this.state.message || "Have fun storming the castle!",
      options
    );
  }

  render() {
    return (
      <Button
      onClick={() => this.showToastr()}
      type="button"
      className="mr-1"
      color="primary"
    >
      Show notification
    </Button>
    );
  }
}

// top-left top-center top-right bottom-left bottom-center and bottom-right

export default Notifications;
