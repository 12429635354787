import * as types from "../constants";


export function setUserType(userType) {
  return {
    type: types.LOGIN_USERTYPE,
    payload: userType
  };
}


