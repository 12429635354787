import React from "react";
import { connect } from 'react-redux'
import { flowCount } from "../redux/actions/licenseActions";
import { Container, Row, Col, Button } from "reactstrap";
import { Link, useLocation, Switch, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import styled, { keyframes } from 'styled-components';
import { slideInDown } from 'react-animations'

const slideAnimation = keyframes`${slideInDown}`;

const SlideDiv = styled.div`
  animation: 1s ${slideAnimation};
`;

const PremisIklanSteps = [
  {
    url: '/new',
    title: 'Pilih Jenis Lesen',
    page: '1'
  },
  {
    url: '/maklumatpemohon',
    title: 'Maklumat Pemohon',
    page: '2'
  },
  {
    url: '/semakansekatan',
    title: 'Semakan Sekatan',
    page: '3'
  },
  {
    url: '/premis',
    title: 'Aktiviti Perniagaan',
    page: '4'
  },
  {
    url: '/borang',
    title: 'Borang Maklumat',
    page: '5'
  },
  {
    url: '/dokumen',
    title: 'Muat Naik Dokumen',
    page: '6'
  },
  {
    url: '/pengesahan',
    title: 'Pengesahan & Akujanji',
    page: '7'
  },
  {
    url: '/',
    title: '',
    page: '8'
  },
];

const PenjajaSteps = [
  {
    url: '/new',
    title: 'Pilih Jenis Lesen',
    page: '1'
  },
  {
    url: '/kategoripenjaja',
    title: 'Kategori Penjaja',
    page: '2'
  },
  {
    url: '/maklumatpemohon',
    title: 'Maklumat Pemohon',
    page: '3'
  },
  {
    url: '/semakansekatan',
    title: 'Semakan Sekatan',
    page: '4'
  },
  {
    url: '/premis',
    title: 'Aktiviti Perniagaan',
    page: '5'
  },
  {
    url: '/borang',
    title: 'Borang Maklumat',
    page: '6'
  },
  {
    url: '/dokumen',
    title: 'Muat Naik Dokumen',
    page: '7'
  },
  {
    url: '/pengesahan',
    title: 'Pengesahan & Akujanji',
    page: '8'
  },
  {
    url: '/',
    title: '',
    page: '9'
  },
];


class ShowHideFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: '',
    };
  }
  
  componentWillMount(){
    //this.props.dispatch(flowCount(0));
  }

  //
  nextPage() {
    var count = this.props.licenseType.count + 1;

    let pageurl;

    if (this.props.licenseType.licenseType === 'premis') {
      pageurl = PremisIklanSteps[count].url
    }else if (this.props.licenseType.licenseType === 'penjaja') {
      pageurl = PenjajaSteps[count].url
    }else{
      pageurl = PremisIklanSteps[count].url
    }

    this.props.history.push(pageurl);
    this.props.dispatch(flowCount(count));
  }

  prevPage() {
    var count = this.props.licenseType.count - 1;
    //this.props.history.push(PremisIklanSteps[count].url);
    if(count>0){
    this.props.dispatch(flowCount(count));
    this.props.history.goBack()
    }else{
      this.props.dispatch(flowCount(0));
      this.props.history.push('new');

    }
  }

  showFormFooter(param) {
    switch (param) {
      case '/new':
        return '';

      case '/kategoripenjaja':
        return '';

      case '/petapenjaja':
        return '';

      case '/maklumatpemohon':
        return '';

      case '/premis':
        return '';

      case '/borang':
        return '';

      case '/dokumen':
        return '';

      case '/semakansekatan':
        return '';

      case '/semakansekatan/pass':
        return '';

      case '/pengesahan':
        return 'd-none';

      default:
        return 'd-none';
    }
  
  }
  render() {

    let locationpath = this.props.location.pathname;

    let nextPageName;

    if (this.props.licenseType.licenseType === 'premis') {
      nextPageName = PremisIklanSteps[this.props.licenseType.count + 1].title
    }else if (this.props.licenseType.licenseType === 'penjaja') {
      nextPageName = PenjajaSteps[this.props.licenseType.count + 1].title
    }else if (this.props.licenseType.licenseType === 'default') {
      nextPageName = ''
    }else{
      nextPageName = PremisIklanSteps[this.props.licenseType.count + 1].title
    }

    let pageNumber;

    if (this.props.licenseType.licenseType === 'premis') {
      pageNumber = 'Halaman ' + PremisIklanSteps[this.props.licenseType.count].page +' / ' + PremisIklanSteps.length
    }else if (this.props.licenseType.licenseType === 'penjaja') {
      pageNumber = 'Halaman ' + PenjajaSteps[this.props.licenseType.count].page +' / ' + PremisIklanSteps.length
    }else if (this.props.licenseType.licenseType === 'default') {
      pageNumber = ''
    }else{
      pageNumber = 'Halaman ' + PremisIklanSteps[this.props.licenseType.count].page +' / ' + PremisIklanSteps.length
    }

    return <footer className={this.showFormFooter(locationpath) + " footer stickybottom"}>
      <Container fluid>
        <Row className="text-muted">
          <Col xs="9" className="text-left">
            <Button onClick={() => this.prevPage()} color="warning" size="lg" outline><FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Kembali</Button>
          </Col>
          <Col xs="3" className="rightbtn text-right">
            <Row>
              <Col xs="6" className="langkah-kedua">
                <SlideDiv><h4 className="mb-0">{nextPageName}</h4></SlideDiv>
  <div className="mb-0">{pageNumber}</div>
              </Col>
              <Col xs="6">
                <Button onClick={() => this.nextPage()} className="my-other-step" color="warning" size="lg" >Seterusnya <FontAwesomeIcon icon={faArrowRight} className="ml-2" /></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  }
}

const Footer = (props) => (
  <ShowHideFooter {...props} location={useLocation()} history={useHistory()} />
);


export default connect(store => ({
  licenseType: store.licenseType,
}))(Footer);
