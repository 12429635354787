import React from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";
import Settings from "../components/Settings";

import loginbg from "../assets/img/chart.png"
import logombsp from "../assets/img/mbsp_logo.png"

import styled, { keyframes } from 'styled-components';
import { fadeInDown, fadeIn } from 'react-animations'

const slideAnimation = keyframes`${fadeInDown}`;
const fade = keyframes`${fadeIn}`;

 
const SlideDiv = styled.div`
  animation: 1s ${slideAnimation};
`;

const FadeDiv = styled.div`
  animation: 1s ${fade};
`;



const Auth = ({ children }) => (
  <React.Fragment>
    <Main className="d-flex w-100 justify-content-center signinbg">
      <Container className="d-flex flex-column">
        <Row className="h-100">
        <Col sm="6" md="6" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
            <SlideDiv><h1 className="display-3 mb-5 font-weight-bold text-white">Urus Lesen Perniagaan Anda.</h1></SlideDiv>
            <FadeDiv><h1 className="font-weight-light text-white mb-5">Kini lebih mudah menguruskan lesen perniagaan anda secara atas talian.</h1></FadeDiv>
            <img src={logombsp} width="90px" className="mb-5"/>
            </div>
          </Col>
          <Col sm="6" md="6" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">{children}</div>
          </Col>
        </Row>
      </Container>
    </Main>
  </React.Fragment>
);

export default Auth;
