import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { setUserType } from "../../redux/actions/loginActions";



import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Jumbotron,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import { User } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch, faArrowRight, faHourglass, faClock, faCheck, faMousePointer, faPencilAlt
} from "@fortawesome/free-solid-svg-icons";

import avatar from "../../assets/img/avatars/avatar.jpg";


class SignIn extends React.Component {
  constructor(props) {
    super(props);
    //this.setUserType = this.setUserType.bind(this);
  }

  /*handleChange(e) {
    this.props.userType(e.target.value);
    //this.setState({userType: event.target.value},
      //localStorage.setItem('userlevel', event.target.value)
      //);
  }*/

  userLevel(param) {
    switch(param) {
      case 'publicuser':
        return '/utama';

        case 'hizwanzameri@gmail.com':
        return '/utama';
        
        case 'jp':
        return '/newsubmission';

        case 'jt':
        return '/newsubmission';

        case 'ppkpk':
        return '/newsubmission';

        case 'pengarah':
        return '/newsubmission';

        case 'datukbandar':
        return '/newsubmission';

        case 'admin':
        return '/permohonan';

      default:
        return '/';
    }
  }
 
  render () {
    const {userType, dispatch } = this.props;
  console.log(userType);
    return (
      <React.Fragment>
    <Container className="p-0">
      <Row>
        <Col lg="10" className="d-flex">
          <Card className="flex-fill w-100">
            <CardBody>
              
              <div className="m-sm-4">
                <h2 className="text-center mb-5">Sila log masuk untuk menguruskan lesen anda.</h2>
                <Form>
                  <FormGroup>
                    <Input
                      bsSize="lg"
                      type="email"
                      name="email"
                      placeholder="Emel atau nama pengguna"
                      className="mb-3"
                       onChange={(e) => dispatch(setUserType(e.target.value))}
                    />
                    <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Kata Laluan"
                      className="mb-3"
                    />
                    <small>
                      <Link to="#" id={"help"}>Terlupa kata laluan?</Link>
                    </small>
                    <UncontrolledTooltip
                      placement="top"
                      target={"help"}
                    >
                      Klik jika terlupa kata laluan.
                    </UncontrolledTooltip>
                  </FormGroup>
                  <div className="mt-3">
                    <Link to={this.userLevel(userType.userType)}>
                      <Button color="primary" size="lg" block>
                        Log Masuk <FontAwesomeIcon icon={faArrowRight} className=" ml-2" />
                      </Button>
                    </Link>
                  </div>
                  <div className="mt-3">
                    <Link to="">
                      <Button color="primary" size="lg" block outline>
                        Daftar Akaun Baru
                      </Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>

  </React.Fragment>

    )
  }
}

export default connect(store => ({
  userType: store.userType,
}))(SignIn);
