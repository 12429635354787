import * as types from "../constants";

const initialState = {
  licenseType: 'default',
  count: 0
};

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
      case types.LICENSE_TYPE:
        return {
          ...state,
          licenseType: actions.payload
        };
        case types.FLOW_COUNT:
      return {
        ...state,
        count: actions.count
      };
  
      default:
        return state;
    }
  }