import * as types from "../constants";


export function setLicenseType(licenseType) {
  return {
    type: types.LICENSE_TYPE,
    payload: licenseType
  };
}

export function flowCount(countNo) {
  return {
    type: types.FLOW_COUNT,
    count: countNo
  };
}