import async from "../components/Async";

import {
  Bell as Bellicon,
  BookOpen as BookOpenIcon,
  Calendar as CalendarIcon,
  CheckSquare as CheckSquareIcon,
  Grid as GridIcon,
  Heart as HeartIcon,
  Layout as LayoutIcon,
  List as ListIcon,
  MapPin as MapPinIcon,
  Monitor as MonitorIcon,
  PieChart as PieChartIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Settings as TetapanIcon,
  Clipboard as LaporanIcon,
  Layers as LainLainIcon,
  File as LesenIcon,
  Grid as UtamaIcon
} from "react-feather";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
import Boxed from "../pages/layouts/Boxed";
import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
import SidebarSticky from "../pages/layouts/SidebarSticky";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeCorporate from "../pages/layouts/ThemeCorporate";
import ThemeModern from "../pages/layouts/ThemeModern";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import Carousel from "../pages/ui-elements/Carousel";
import EmbedVideo from "../pages/ui-elements/EmbedVideo";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";

// Notifications
import Notifications from "../pages/notifications/Notifications";

// Pages
import Profile from "../pages/pages/Profile";
import Settings from "../pages/pages/Settings";
import Clients from "../pages/pages/Clients";
import Projects from "../pages/pages/Projects";
import Invoice from "../pages/pages/Invoice";
import Pricing from "../pages/pages/Pricing";
import Tasks from "../pages/pages/Tasks";
import Chat from "../pages/pages/Chat";

// Documentation
import Introduction from "../pages/docs/Introduction";
import Installation from "../pages/docs/Installation";
import Plugins from "../pages/docs/Plugins";
import Changelog from "../pages/docs/Changelog";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const Ecommerce = async(() => import("../pages/dashboards/Ecommerce"));
const Crypto = async(() => import("../pages/dashboards/Crypto"));
const Social = async(() => import("../pages/dashboards/Social"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicInputs = async(() => import("../pages/forms/BasicInputs"));
const AdvancedInputs = async(() => import("../pages/forms/AdvancedInputs"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));
const Wizard = async(() => import("../pages/forms/Wizard"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const PaginationTables = async(() => import("../pages/tables/Pagination"));
const RowSelectionTables = async(() => import("../pages/tables/RowSelection"));
const ExportCsvTables = async(() => import("../pages/tables/ExportCsv"));
const ExpandableRowsTables = async(() =>
  import("../pages/tables/ExpandableRows")
);

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

//Renegade
const MainPage = async(() => import("../pages/main/Main"));
const MainSubmittedPage = async(() => import("../pages/main/MainSubmitted"));
const MainPaidPage = async(() => import("../pages/main/MainPaid"));
const MainActivePage = async(() => import("../pages/main/MainActive"));
const NewPage = async(() => import("../pages/new_license/New"));
const PremisActivity = async(() => import("../pages/new_license/PremisActivity"));
const PremisForm = async(() => import("../pages/new_license/Form"));
const DocumentPage = async(() => import("../pages/new_license/Document"));
const RestrictionPage = async(() => import("../pages/new_license/Restriction"));
const RestrictionPassPage = async(() => import("../pages/new_license/RestrictionPass"));
const PengesahanPage = async(() => import("../pages/new_license/Pengesahan"));
const PermohonanDihantarPage = async(() => import("../pages/new_license/PermohonanDihantar"));
const ApplicantInfo = async(() => import("../pages/new_license/ApplicantInfo"));

//Penjaja
const PenjajaKategori = async(() => import("../pages/new_license/licenses/penjaja/KategoriPenjaja"));
const PenjajaMap = async(() => import("../pages/new_license/licenses/penjaja/PenjajaMap"));

//Renegade Admin
const NewSubmission = async(() => import("../pages/new_submission_list/new_submission_list"));
const processPage = async(() => import("../pages/process/process"));
const prosessJT = async(() => import("../pages/process/processJT"));
const prosessPP = async(() => import("../pages/process/processPPKPK"));
const prosessDB = async(() => import("../pages/process/processDB"));
const prosessP = async(() => import("../pages/process/processP"));
const applications = async(() => import("../pages/management/applications"));
const ActiveLicense = async(() => import("../pages/management/active_license"));
const ViewLicense = async(() => import("../pages/management/view_license"));
const LesenLuput = async(() => import("../pages/management/LesenLuput"));
const LesenHampirLuput = async(() => import("../pages/management/LesenHampirLuput"));
const LesenDibatalkan = async(() => import("../pages/management/LesenDibatalkan"));
const SenaraiHitam = async(() => import("../pages/management/SenaraiHitam"));
const PremisMakanan = async(() => import("../pages/management/PremisMakanan"));
const PekerjaAsing = async(() => import("../pages/management/PekerjaAsing"));
const Pembaharuan = async(() => import("../pages/management/Pembaharuan"));
const Pindaan = async(() => import("../pages/management/Pindaan"));
const Notis = async(() => import("../pages/management/Notis"));
const SuratRasmi = async(() => import("../pages/management/SuratRasmi"));
const Perundangan = async(() => import("../pages/management/Perundangan"));
const HTBPP = async(() => import("../pages/management/Htbpp"));
const SewaanPetakKhas = async(() => import("../pages/management/SewaanPetakKhas"));
const KodPerniagaan = async(() => import("../pages/management/KodPerniagaan"));



// Routes
const landingRoutes = {
  path: "/",
  name: "Sign In Page",
  component: SignIn,
  children: null
};

const mainRoutes = {
  path: "/utama",
  name: "Utama",
  icon: SlidersIcon,
  component: MainPage,
  containsHome: true,
  children: null
};

const adminMainRoutes = {
  path: "/permohonan",
  name: "Laman Utama",
  icon: UtamaIcon,
  header: "Utama",
  containsHome: true,
  children: [
    {
      path: "/permohonan",
      name: "Utama",
      component: applications
    },
    {
      path: "/permohonan/baharu",
      name: "Permohonan Baru",
      component: NewSubmission
    },
    {
      path: "/permohonan/pembaharuan",
      name: "Pembaharuan",
      component: Pembaharuan
    },
    {
      path: "/permohonan/pindaan",
      name: "Pindaan",
      component: Pindaan
    }
  ]
};

const penjajaCategoryRoutes = {
  path: "/kategoripenjaja",
  name: "Kategori Penjaja",
  component: PenjajaKategori
};

const penjajaMapRoutes = {
  path: "/petapenjaja",
  name: "Map",
  component: PenjajaMap
};

const mainSubmittedRoutes = {
  path: "/utama/dihantar",
  name: "Create New License",
  component: MainSubmittedPage
};
const mainPaidRoutes = {
  path: "/utama/dibayar",
  name: "Create New License",
  component: MainPaidPage
};
const mainActiveRoutes = {
  path: "/utama/aktif",
  name: "Create New License",
  component: MainActivePage
};

const newRoutes = {
  path: "/new",
  name: "Create New License",
  component: NewPage
};

const premisRoutes = {
  path: "/premis",
  name: "Create New License",
  component: PremisActivity
};

const formPremisRoutes = {
  path: "/borang",
  name: "Create New License",
  component: PremisForm
};

const processRoute = {
  path: "/process",
  name: "License Process",
  component: processPage
};

const prosessJTRoute = {
  path: "/processJT",
  name: "License Process",
  component: prosessJT
};

const prosessPPRoute = {
  path: "/processPP",
  name: "License Process",
  component: prosessPP
};

const prosessDBRoute = {
  path: "/processDB",
  name: "License Process",
  component: prosessDB
};

const prosessPRoute = {
  path: "/processP",
  name: "License Process",
  component: prosessP
};

const NewSubmissionRoute = {
  path: "/newSubmission",
  name: "newSubmission",
  component: NewSubmission
};

const documentRoutes = {
  path: "/dokumen",
  name: "Create New License",
  component: DocumentPage
};

const restrictionRoutes = {
  path: "/semakansekatan",
  name: "Create New License",
  component: RestrictionPage
};

const restrictionpassRoutes = {
  path: "/semakansekatan/pass",
  name: "Create New License",
  component: RestrictionPassPage
};

const pengesahanRoutes = {
  path: "/pengesahan",
  name: "Create New License",
  component: PengesahanPage
};

const permohonandihantarRoutes = {
  path: "/permohonandihantar",
  name: "Create New License",
  component: PermohonanDihantarPage
};

const applicantinfoRoutes = {
  path: "/maklumatpemohon",
  name: "Create New License",
  component: ApplicantInfo
};

const requestRoutes = {
  path: "/requests",
  name: "All Requests",
  icon: SlidersIcon,
  component: Blank,
  children: null
};

const reportRoutes = {
  path: "/reporting",
  name: "Reporting",
  icon: SlidersIcon,
  component: Blank,
  children: null
};

const applicationsRoutes = {
  path: "/applications",
  name: "Applications",
  icon: SlidersIcon,
  component: applications,
  children: null
};

const activelicenseRoutes = {
  path: "/lesen/aktif",
  name: "Lesen Aktif",
  icon: LesenIcon,
  header: "Lesen",
  children: [
    {
      path: "/lesen/aktif/semua",
      name: "Semua",
      component: ActiveLicense
    },
    {
      path: "/lesen/aktif/luput",
      name: "Luput",
      component: LesenLuput
    },
    {
      path: "/lesen/aktif/hampirluput",
      name: "Hampir Luput",
      component: LesenHampirLuput
    },
    {
      path: "/lesen/aktif/dibatalkan",
      name: "Dibatalkan",
      component: LesenDibatalkan
    },
    {
      path: "/lesen/aktif/senaraihitam",
      name: "Senarai Hitam",
      component: SenaraiHitam
    },
    {
      path: "/lesen/aktif/premismakanan",
      name: "Premis Makanan",
      component: PremisMakanan
    },
    {
      path: "/lesen/aktif/pekerjaasing",
      name: "Pekerja Asing",
      component: PekerjaAsing
    },
  ]
};

const viewlicenseRoutes = {
  path: "/lesen/:id",
  component: ViewLicense
};

const lainlainRoutes = {
  path: "/lainlain",
  name: "Lain-lain",
  icon: LainLainIcon,
  children: [
    {
      path: "/lainlain/notis",
      name: "Notis",
      component: Notis
    },
    {
      path: "/lainlain/suratrasmi",
      name: "Surat Rasmi",
      component: SuratRasmi
    },
    {
      path: "/lainlain/perundangan",
      name: "Perundangan",
      component: Perundangan
    },
    {
      path: "/lainlain/htbpp",
      name: "Hari Tanpa Beg Plastik Percuma",
      component: HTBPP
    },
    {
      path: "/lainlain/petakkhas",
      name: "Sewaan Petak Khas",
      component: SewaanPetakKhas
    }
  ]
};

const laporanRoutes = {
  path: "/laporan",
  name: "Laporan",
  icon: LaporanIcon,
  component: applications
};

const tetapanRoutes = {
  path: "/tetapan",
  name: "Tetapan",
  icon: TetapanIcon,
  children: [
    {
      path: "/tetapan/kodperniagaan",
      name: "Kod Perniagaan",
      component: KodPerniagaan
    },
    {
      path: "/tetapan/pentadbirsistem",
      name: "Pentadbir Sistem",
      component: ActiveLicense
    }
  ]
};

//template routes
const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboards",
  header: "Pages",
  badgeColor: "primary",
  badgeText: "5",
  icon: SlidersIcon,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      component: Default
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics
    },
    {
      path: "/dashboard/e-commerce",
      name: "E-commerce",
      component: Ecommerce
    },
    {
      path: "/dashboard/social",
      name: "Social",
      component: Social
    },
    {
      path: "/dashboard/crypto",
      name: "Crypto",
      component: Crypto,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: LayoutIcon,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/clients",
      name: "Clients",
      component: Clients
    },
    {
      path: "/pages/projects",
      name: "Projects",
      component: Projects
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/tasks",
      name: "Tasks",
      component: Tasks
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: MonitorIcon,
  children: [
    {
      path: "/layouts/sidebar-sticky",
      name: "Sticky Sidebar",
      component: SidebarSticky
    },
    {
      path: "/layouts/sidebar-collapsed",
      name: "Sidebar Collapsed",
      component: SidebarCollapsed
    },
    {
      path: "/layouts/boxed",
      name: "Boxed Layout",
      component: Boxed
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-corporate",
      name: "Corporate Theme",
      component: ThemeCorporate,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/layouts/theme-modern",
      name: "Modern Theme",
      component: ThemeModern,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const documentationRoutes = {
  path: "/docs",
  name: "Documentation",
  icon: BookOpenIcon,
  children: [
    {
      path: "/docs/introduction",
      name: "Introduction",
      component: Introduction
    },
    {
      path: "/docs/installation",
      name: "Getting Started",
      component: Installation
    },
    {
      path: "/docs/plugins",
      name: "Plugins",
      component: Plugins
    },
    {
      path: "/docs/changelog",
      name: "Changelog",
      component: Changelog
    }
  ]
};

const uiRoutes = {
  path: "/ui",
  name: "UI Elements",
  header: "Tools & Components",
  icon: GridIcon,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/ui/carousel",
      name: "Carousel",
      component: Carousel
    },
    {
      path: "/ui/embed-video",
      name: "Embed Video",
      component: EmbedVideo
    },
    {
      path: "/ui/general",
      name: "General",
      component: General,
      badgeColor: "info",
      badgeText: "10+"
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography
    }
  ]
};

const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: HeartIcon,
  badgeColor: "info",
  badgeText: "1500+",
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome
    }
  ]
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: CheckSquareIcon,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts
    },
    {
      path: "/forms/basic-inputs",
      name: "Basic Inputs",
      component: BasicInputs
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups
    }
  ]
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: ListIcon,
  component: BootstrapTables,
  children: null
};

const formPluginsRoutes = {
  path: "/form-plugins",
  name: "Form Plugins",
  icon: CheckSquareIcon,
  header: "Plugin & Addons",
  children: [
    {
      path: "/form-plugins/advanced-inputs",
      name: "Advanced Inputs",
      component: AdvancedInputs
    },
    {
      path: "/form-plugins/editors",
      name: "Editors",
      component: Editors
    },
    {
      path: "/form-plugins/validation",
      name: "Validation",
      component: Validation
    },
    {
      path: "/form-plugins/wizard",
      name: "Wizard",
      component: Wizard
    }
  ]
};

const advancedTablesRoutes = {
  path: "/advanced-tables",
  name: "Advanced Tables",
  icon: ListIcon,
  children: [
    {
      path: "/advanced-tables/pagination",
      name: "Pagination",
      component: PaginationTables
    },
    {
      path: "/advanced-tables/row-selection",
      name: "Row Selection",
      component: RowSelectionTables
    },
    {
      path: "/advanced-tables/expandable-rows",
      name: "Expandable Rows",
      component: ExpandableRowsTables
    },
    {
      path: "/advanced-tables/export-csv",
      name: "Export CSV",
      component: ExportCsvTables
    }
  ]
};

const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: PieChartIcon,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const notificationsRoutes = {
  path: "/notifications",
  name: "Notifications",
  icon: Bellicon,
  component: Notifications,
  children: null
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: MapPinIcon,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: CalendarIcon,
  component: Calendar,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  landingRoutes,
  dashboardRoutes,
  pageRoutes,
  layoutRoutes,
  documentationRoutes,
  uiRoutes,
  iconRoutes,
  formRoutes,
  tableRoutes,
  formPluginsRoutes,
  advancedTablesRoutes,
  chartRoutes,
  notificationsRoutes,
  mapRoutes,
  calendarRoutes,
  privateRoutes,
  //menu starts here
  mainRoutes,
  adminMainRoutes,
  requestRoutes,
  reportRoutes,
  newRoutes,
  penjajaCategoryRoutes,
  penjajaMapRoutes,
  premisRoutes,
  formPremisRoutes,
  documentRoutes,
  restrictionRoutes,
  restrictionpassRoutes,
  pengesahanRoutes,
  permohonandihantarRoutes,
  applicantinfoRoutes,
  NewSubmissionRoute,
  processRoute,
  mainPaidRoutes,
  mainSubmittedRoutes,
  mainActiveRoutes,
  prosessJTRoute,
  prosessDBRoute,
  prosessPPRoute,
  prosessPRoute,
  applicationsRoutes,
  activelicenseRoutes,
  viewlicenseRoutes,
  lainlainRoutes,
  laporanRoutes,
  tetapanRoutes
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

export const adminSidebar = [mainRoutes, adminMainRoutes, activelicenseRoutes, lainlainRoutes, laporanRoutes, tetapanRoutes];

export const publicSidebar = [mainRoutes];

// All routes
export default adminSidebar;

